<template>
	<div class="content">
		<base-header class="pb-6">
			<div class="row align-items-center py-4">
				<div class="col-lg-6 col-7">
					<h6 class="h2 text-white d-inline-block mb-0">KAREN Weight Settings</h6>
					<nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
						<route-bread-crumb></route-bread-crumb>
					</nav>
				</div>
			</div>
		</base-header>

		<div class="container-fluid mt--6" v-loading="loading">
			<div class="row">
				<div class="col">
					<card>
						<template>
							<div slot="header">
								<div class="row align-items-center">
									<div class="col-8">
										<h3 class="mb-0" id="lead_details_heading">
											KAREN Weight Settings - {{ country }}
										</h3>
									</div>
									<div class="col-4 text-right">
										<a href="javascript:" class="btn btn-sm btn-secondary" @click="resetToDefault">Reset to Default</a>
										<a href="javascript:" class="btn btn-sm btn-primary" @click="save">Save</a>
									</div>
								</div>
							</div>
							<div>
								<h6 class="heading-small text-muted mb-2">Set your own values for the karen
									recommendations algorithm
								</h6>
							</div>
							<form>
								<table class="base-values">
									<thead>
										<tr>
											<th>Weight</th>
											<th>Area</th>
											<th>Filter Details</th>
											<th>Included in Search Algorithm</th>
										</tr>
									</thead>
									<tbody>

										<tr v-for="(value, setting) in countrySettings" :key="setting">
											<td>
												<weight-selector :defaultWeight="value" @update:weight="selectedWeight => handleWeightChange(selectedWeight, setting)"></weight-selector>
											</td>
											<td>{{ settingData[setting]?.title }}</td>
											<td>Description</td>
											<td class="text-center"><i class="fa fa-check"></i></td>
										</tr>
									</tbody>
								</table>
								<div class="table-summary">
									<div class="row">
										<div class="col text-left total-weight">Total Weight: <span
											:class="totalWeightValueDenied ? `total-weight-val-denied` : `total-weight-val-accepted`">{{ totalWeightValue }}</span>/100</div>
										<div class="col text-right total-weight-msg">
											<span v-if="totalWeightValueDenied" class="total-weight-msg-denied">Your total weight should be equal to 100,
												please fix before saving.</span>
										</div>
									</div>
								</div>

								<table class="addon-values">
									<tbody>
										<tr v-for="(value, setting) in secondaryWeightSettings" :key="setting">
											<td>
												<weight-selector :defaultWeight="value" :disabled="! secondarySettingsActive[setting]" @update:weight="selectedWeight => handleSecondaryWeightChange(selectedWeight, setting)"></weight-selector>
											</td>
											<td>{{ settingData[setting]?.title }}</td>
											<td>Description</td>
											<td>
												<div class="checkbox">
													<input type="checkbox" :id="`checkbox_input_` + setting" v-model="secondarySettingsActive[setting]">
													<label :for="`checkbox_input_` + setting"></label>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="table-summary">
									<div class="row">
										<div class="col text-left">Select the above addons as needed.</div>
									</div>
								</div>
							</form>
						</template>
					</card>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import WeightSelector from "@/views/Widgets/KarenV2/WeightSelector";
import { mapState } from "vuex";
import karenService from "@/services/karen.service";
import { Notification } from 'element-ui';

export default {
	components: {
		RouteBreadCrumb,
		WeightSelector,
	},
	data() {
		return {
			primaryWeightSettings: {
				AU: {
					location: 0,
					style: 0,
					number_of_guests: 0,
					pricing_per_person: 0
				},

				UK: {
					budget: 0,
					number_of_guests: 0,
					style: 0,
					location: 0,
				},
			},
			secondaryWeightSettings: { 
				accommodation_on_site: 0,
				external_catering_permitted: 0,
				ceremony_on_site: 0,
				outdoor_space: 0,
				mandap_ceremony: 0,
				late_license: 0,
			},
			secondarySettingsActive: { 
				accommodation_on_site: 0,
				external_catering_permitted: 0,
				ceremony_on_site: 0,
				outdoor_space: 0,
				mandap_ceremony: 0,
				late_license: 0,
			},
			settingData: {},
			loading: false,
		};
	},
	computed: {
		...mapState("country/", {
			country: (state) => state.country,
		}),

		countrySettings() {
			return this.primaryWeightSettings[this.country];
		},

		totalWeightValueDenied() {
			return this.totalWeightValue !== 100;
		},

		totalWeightValue() {
			let total = 0;
			Object.values(this.countrySettings).forEach(value => {
				total += parseInt(value, 10); 
			});
			return total;
		},
	},
	created() {
		this.getInitialSettings();
	},
	methods: {
		handleWeightChange(weight, setting) {
			this.primaryWeightSettings[this.country][setting] = weight;
		},

		handleSecondaryWeightChange(weight, setting) {
			this.secondaryWeightSettings[setting] = weight;
		},

		getSettingTitle(setting) {
			return setting
				.split('_')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
		},

		async getInitialSettings() {
			this.loading = true;
			try {
				let response = await karenService.getSettings(this.country);
				if (response && response.data) {
					this.settingData = response.data;
					this.setInitialValue();
				}
			} catch (error) {
				console.error("Failed to fetch settings:", error);
			} 
			this.loading = false; 
		},

		setInitialValue() {
			if (!this.settingData) {
				console.log('here');
				return;
			}

			// Reset settings for the specific country to ensure clean state
			Object.keys(this.primaryWeightSettings[this.country]).forEach(key => {
				this.primaryWeightSettings[this.country][key] = 0;
			});

			// Reset secondaryWeightSettings
			Object.keys(this.secondaryWeightSettings).forEach(key => {
				this.secondaryWeightSettings[key] = 0;
			});

			// Iterate over the data and assign values
			Object.keys(this.settingData).forEach(settingKey => {
				const setting = this.settingData[settingKey];
				let settingName = setting.key;
				if (this.primaryWeightSettings[this.country].hasOwnProperty(settingName) && setting.primary) {
					this.primaryWeightSettings[this.country][settingName] = setting.weight;
				} else if (this.secondaryWeightSettings.hasOwnProperty(settingName)) {
					this.secondaryWeightSettings[settingName] = setting.weight;
					this.secondarySettingsActive[settingName] = setting.active;
				}
			});
		},

		resetToDefault() {
			if (!this.settingData || !Array.isArray(this.settingData)) {
				return;
			}

			// Reset settings for the specific country to ensure clean state
			Object.keys(this.primaryWeightSettings[this.country]).forEach(key => {
				this.primaryWeightSettings[this.country][key] = 0;
			});

			// Reset secondaryWeightSettings
			Object.keys(this.secondaryWeightSettings).forEach(key => {
				this.secondaryWeightSettings[key] = 0;
			});

			// Iterate over the data and assign values
			this.settingData.forEach(setting => {
				let settingName = setting.key;
				if (this.primaryWeightSettings[this.country].hasOwnProperty(settingName) && setting.primary) {
					this.primaryWeightSettings[this.country][settingName] = setting.default_weight;
				} else if (this.secondaryWeightSettings.hasOwnProperty(settingName)) {
					this.secondaryWeightSettings[settingName] = setting.default_weight;
					this.secondarySettingsActive[settingName] = true;
				}
			});
		},

		async save() {
			if (this.totalWeightValueDenied) {
				return;
			}

			this.loading = true;
			try {

				// Create a new object to avoid mutating the original settings
				let settingsWeight = {...this.primaryWeightSettings[this.country]}; 

				// Loop over secondaryWeightSettings and merge them into the new merged object
				Object.entries(this.secondaryWeightSettings).forEach(([key, value]) => {
					settingsWeight[key] = value;
				});

				await karenService.saveSettings(this.country, settingsWeight, this.secondarySettingsActive);

				Notification.success({
					title: 'Success',
					message: 'Karen weight settings for ' + this.country.toUpperCase() + ' have been saved successfully!',
					type: 'success',
				});
			} catch (error) {
				Notification.error({
					title: 'Error',
					message: 'An error occurred while saving Karen\'s weight settings in the ' + this.country.toUpperCase() + '. Please try again later.',
					type: 'error',
				});
			} 
			this.loading = false;
		},
	},
};
</script>

<style lang="scss" scoped >
table,
.table-summary {
	width: 100%;
	border: 1px solid #D9D9D9;
	border-top: 0px;
}

table {

	th,
	td {
		padding: 10px;
	}

	thead,
	tr {
		border-bottom: 1px solid #D9D9D9;
	}

	td {
		border-right: 1px solid #D9D9D9;
	}

	tbody tr td {
		&:nth-of-type(1) {
			width: 10%;
		}

		&:nth-of-type(2) {
			width: 15%;
		}

		&:nth-of-type(3) {
			width: 50%;
		}

		&:nth-of-type(4) {
			width: 15%;
		}
	}

	&:first-of-type {
		border-bottom: 0px;
		border-spacing: 0;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-style: hidden;
		box-shadow: inset 0 0 0 1px #D9D9D9;
	}

	&:not(:first-of-type) {
		border-top: 0px;
	}

	&.base-values {
		thead tr th {
			text-transform: uppercase;
		}
	}

	&.addon-values {
		tbody tr {
			background-color: #F6F6F6;

			&.addon-enabled {
				background-color: #ffffff;
			}
		}
	}

	.fa-check {
		color: #1171EF;
	}

	.checkbox {
		text-align: center;

		label {
			cursor: pointer;
		}

		input {
			opacity: 0;
		}

		label {
			margin: 0;

			&:before {
				content: '';
				background: #fff;
				display: inline-block;
				vertical-align: middle;
				width: 20px;
				height: 20px;
				margin-right: 10px;
				text-align: center;
				margin-top: 5px;
				outline: 1px solid #ccc;
				border: 2px solid #fff;
				top: -5px;
				color: #fff;
				position: relative;
				font-size: 18px;
				-webkit-transition: all ease-in-out 0.15s, border ease-in-out 0.15s;
				-o-transition: all ease-in-out 0.15s, border ease-in-out 0.15s;
				transition: all ease-in-out 0.15s, border ease-in-out 0.15s;
			}

			&:hover:before {
				border: 2px solid #1171EF;
				outline: 1px solid #1171EF;
			}
		}

		input[type="checkbox"]:checked+label:before {
			color: #1171EF;
			border: 2px solid #1171EF;
			outline: 1px solid #1171EF;
			font-family: FontAwesome;
			content: "\f00c";
			line-height: 19px;
			font-size: 14px;
		}
	}

}

.table-summary {
	padding: 10px;

	&:last-of-type {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.total-weight {
	font-weight: 600;
	text-transform: uppercase;

	.total-weight-val-accepted {
		color: #58BF19;
	}

	.total-weight-val-denied {
		color: #BF1A1A;
	}
}

.total-weight-msg {
	font-weight: 600;

	.total-weight-msg-accepted {
		color: #58BF19;
	}

	.total-weight-msg-denied {
		color: #BF1A1A;
	}
}
</style>