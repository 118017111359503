<template>
	<el-select
		class="karen-settings-weight-selector" v-model="selectedWeight" @change="emitWeight" :disabled="disabled"
		size="mini">
		<el-option v-for="weight in weights" :key="weight" :value="weight" >{{ weight }}</el-option>
	</el-select>
</template>
  
<script>
import { Select, Option } from 'element-ui';
export default {
	name: 'WeightSelector',
	components: {
		[Select.name]: Select,
		[Option.name]: Option,
	},
	props: {
		defaultWeight: Number,
		disabled: Boolean,
	},
	data() {
		return {
			selectedWeight: this.defaultWeight, // Stores the selected weight
			weights: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100], // Array of weights
		};
	},
	methods: {
		emitWeight() {
			this.$emit('update:weight', this.selectedWeight); // Emitting an event when weight is selected/changed
		},
	},
	watch: {
		defaultWeight: function() {
			this.selectedWeight = this.defaultWeight;
		},
	},
};
</script>
<style>
.karen-settings-weight-selector .el-input--mini .el-input__inner {
  height: 30px;
  line-height: 30px;
}
</style>
  